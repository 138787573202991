@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Medium';
    src: url('fonts/Roboto-Medium.ttf');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Light.ttf');
    font-weight: 100;
    font-style: normal;
}
