.tabContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    color: #000000DE;
}

.switcher {
    display: flex;
    justify-content: space-around;
    height: 50px;
}

.radio {
    justify-content: center !important;
}

.radio svg {
    color: #8BD1E8
}
