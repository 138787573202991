.tabContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    color: #000000DE;
    height: 100%;
}
.container {
    height: 100%;
}
.title {
    flex: 40%;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    color: #000000DE;

}
.contentFlex {
    display: flex;
    flex-direction: column;
    height: 100%;

}
.divider {
    flex: 100%;
}
.buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
}
